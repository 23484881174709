









import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MegaMenu',
  props: {
    isSticky: {
      type: Boolean,
      required: true,
    },
  },
});
