

























import ImageWrapper from '~/components/image/ImageWrapper.vue';
import {
  defineComponent,
} from '@nuxtjs/composition-api';
import {
  useUiHelpers,
  useImage,
} from '~/composables';
import { addBasePath } from '~/helpers/addBasePath';
import { getCategoryImage } from '~/helpers/getCategoryImage';

export default defineComponent({
  name: 'BrandTile',
  components: {
    ImageWrapper,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { getMagentoImage } = useImage();

    return {
      getCatLink,
      addBasePath,
      getCategoryImage,
      getMagentoImage,
    };
  },
});
