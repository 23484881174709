











































































import {
  SfLink,
} from '@storefront-ui/vue';
import ImageWrapper from '~/components/image/ImageWrapper.vue';
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useUiHelpers, useImage } from '~/composables';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import SubMenu from './SubMenu/SubMenu.vue';

export default defineComponent({
  name: 'MegaMenu',
  components: {
    SfLink,
    ImageWrapper,
    SubMenu,
  },
  props: {
    currentCategory: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup({ currentCategory }, { emit }) {
    const { getCatLink } = useUiHelpers();
    const hoveredCategoryUid = ref(null);
    const { getMagentoImage } = useImage();

    const currentImages = computed(() => {
      const bannnerCandidateCategory = hoveredCategoryUid.value
        ? currentCategory?.children?.find((c) => (c.uid === hoveredCategoryUid.value))
        : currentCategory;

      const images = [];
      if (bannnerCandidateCategory?.megamenu_image_1_link) {
        images.push({
          src: bannnerCandidateCategory.megamenu_image_1,
          url: bannnerCandidateCategory.megamenu_image_1_link,
        });
      }
      if (bannnerCandidateCategory?.megamenu_image_2_link) {
        images.push({
          src: bannnerCandidateCategory.megamenu_image_2,
          url: bannnerCandidateCategory.megamenu_image_2_link,
        });
      }
      return images;
    });

    const onMouseLeave = () => {
      emit('on-mouse-leave');
    };
    const onMouseOver = (uid) => {
      if (hoveredCategoryUid.value === uid) return;
      hoveredCategoryUid.value = uid;
    };

    return {
      getCategoryImage,
      getCatLink,
      onMouseLeave,
      onMouseOver,
      currentImages,
      getMagentoImage,
    };
  },
});
