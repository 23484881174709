










































































import {
  defineComponent, useRouter, useContext, computed,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage, SfButton } from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';

export default defineComponent({
  components: {
    SfImage,
    SfButton,
  },
  setup() {
    const {
      toggleWishlistSidebar,
      toggleCartSidebar,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const wishlistStore = useWishlistStore();
    const { totalItems } = useCartView();

    const wishlistItemsCount = computed<number>(
      () => wishlistStore.wishlist?.items_count ?? 0,
    );

    const toggleWishlistSidebarIfAuth = () => {
      if (isAuthenticated.value) {
        toggleWishlistSidebar();
      } else {
        router.push('/authentication');
      }
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        router.push('/authentication');
      }
    };

    return {
      addBasePath,
      toggleWishlistSidebarIfAuth,
      handleAccountClick,
      toggleCartSidebar,
      wishlistItemsCount,
      isAuthenticated,
      totalItems,
    };
  },
});
