









import {
  defineComponent, useFetch, ref,
} from '@nuxtjs/composition-api';
import { useStoryblokView } from '~/modules/storyblok/composables/useStoryblokView';

export default defineComponent({
  components: {},
  setup() {
    const { getStoryBySlug } = useStoryblokView();
    const story = ref(null);

    const { fetch } = useFetch(async () => {
      const { story: sbStory } = await getStoryBySlug({
        slug: 'general/headerslider',
        redirectOnError: true,
      });
      story.value = sbStory;
    });

    fetch();

    return {
      story,
    };
  },
});
