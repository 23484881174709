






















































































import {
  defineComponent,
  ref,
  useContext,
  computed, watch,
} from '@nuxtjs/composition-api';
import ImageWrapper from '~/components/image/ImageWrapper.vue';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import {
  useUiHelpers, useImage, useCategorySearch, SortEnum, CategoryTree, UseCategorySearchParams, CategorySortInput,
} from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { findActiveCategory } from '~/modules/catalog/category/helpers/findActiveCategory';
import MegaMenu from '~/components/MainHeader/MegaMenu/MegaMenu.vue';
import MegaMenuBrands from '~/components/MainHeader/MegaMenu/MegaMenuBrands.vue';
import MegaMenuCategories from '~/components/MainHeader/MegaMenu/MegaMenuCategories.vue';

export type StaticHovered = 'brands' | '';

export default defineComponent({
  components: {
    MegaMenu,
    ImageWrapper,
    MegaMenuBrands,
    MegaMenuCategories,
  },
  props: {
    isSticky: {
      type: Boolean,
      required: true,
    },
    showHeaderMenu: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { env } = useContext();
    const { getCatLink } = useUiHelpers();
    const currentCategory = ref(null);
    const { categoryTree } = useTraverseCategory();
    const { search, result, loading } = useCategorySearch();
    const openMenuWithDelay = ref(true);
    const menuTimeoutId = ref(null);
    const staticHovered = ref<StaticHovered>('');
    const staticSearchParams = ref<UseCategorySearchParams & { pageSize?: number, sort?:CategorySortInput }>(null);

    const fetchStaticCategory = async (value: StaticHovered) => {
      if (!value) return;

      if (value === 'brands') {
        staticSearchParams.value = {
          filters: {
            parent_id: {
              eq: '85', // 85 is the id of the brand category
            },
            promote_in_megamenu: {
              eq: '1', // 1 is the value of promote_in_megamenu field in the category
            },
          },
          sort: {
            mainsite_priority: SortEnum.Asc,
          },
          pageSize: 30,
        };
      }
      if (!result.value && !loading.value && staticSearchParams.value) {
        await search(staticSearchParams.value);
      }
      staticSearchParams.value = null;
    };

    const hoverResolver = (element: CategoryTree | StaticHovered) => {
      if (typeof element === 'string') {
        staticHovered.value = element;
      } else {
        currentCategory.value = element as CategoryTree;
      }
    };

    const onMouseOverHandler = (element: CategoryTree | StaticHovered) => {
      if (openMenuWithDelay.value) {
        clearTimeout(menuTimeoutId.value as string | number);
        menuTimeoutId.value = setTimeout(() => {
          if (!openMenuWithDelay.value) return;
          hoverResolver(element);
          openMenuWithDelay.value = false;
        }, 500);
        return;
      }
      hoverResolver(element);
    };

    watch(
      () => staticHovered.value,
      async (newValue) => {
        await fetchStaticCategory(newValue);
      },
    );

    const hasChildren = (category) => Boolean(category?.children?.length);

    const cleanCurrentCategory = () => {
      currentCategory.value = null;
      hoverResolver('');
    };
    const onHeaderMenuLeave = () => {
      emit('on-mouse-leave');
      cleanCurrentCategory();

      openMenuWithDelay.value = true;
      clearTimeout(menuTimeoutId.value as string | number);
    };

    const categoryTreeNavigation = computed(() => {
      const mainCategoryUid = env.VSF_MAGENTO_MAIN_CATEGORY_UID;
      return categoryTree.value === null
        ? null
        : findActiveCategory(
          categoryTree.value,
          mainCategoryUid as string,
          'uid',
        );
    });

    const { getMagentoImage } = useImage();

    return {
      currentCategory,
      hasChildren,
      onMouseOverHandler,
      onHeaderMenuLeave,
      cleanCurrentCategory,
      categoryTreeNavigation,
      openMenuWithDelay,
      getCategoryImage,
      getCatLink,
      getMagentoImage,
      staticHovered,
      staticResult: result || {},
      staticLoading: loading,
    };
  },
});
