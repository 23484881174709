























import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'SubMenu',
  props: {
    parentCategory: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(_, { emit }) {
    const categories = ref([]);
    const { getCatLink } = useUiHelpers();

    const onMouseLeave = () => {
      emit('on-mouse-leave');
    };

    const hasChildren = (category) => Boolean(category?.children.length > 0);

    return {
      getCatLink,
      hasChildren,
      categories,
      onMouseLeave,
    };
  },
});
