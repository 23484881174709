

































































import LazyHydrate from 'vue-lazy-hydration';
import {
  defineComponent, onMounted, ref, onUnmounted,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useUiState } from '~/composables';
import { SfImage } from '@storefront-ui/vue';
import { throttle } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProductModalStore } from '~/stores/productModal';
import HeaderMenu from '~/components/MainHeader/HeaderMenu/HeaderMenu.vue';
import Logo from '~/components/MainHeader/Logo/Logo.vue';
import StaticLinks from '~/components/MainHeader/StaticLinks/StaticLinks.vue';
import SearchBar from '~/components/MainHeader/SearchBar/SearchBar.vue';
import Toolbar from '~/components/MainHeader/Toolbar/Toolbar.vue';
import Slider from '~/components/MainHeader/Slider/Slider.vue';

export default defineComponent({
  name: 'MainHeader',
  components: {
    Logo,
    SearchBar,
    Toolbar,
    StaticLinks,
    HeaderMenu,
    Slider,
    SfImage,
    LazyHydrate,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  setup() {
    const { isCartSidebarOpen, isWishlistSidebarOpen } = useUiState();
    const productModalStore = useProductModalStore();
    const { isModalOpen } = storeToRefs(productModalStore);
    const isSticky = ref(false);
    const showHeaderMenu = ref(false);

    const handleScroll = throttle(() => {
      // window.scrollY - doesn't works on mobile
      isSticky.value = document.documentElement.scrollTop > 0 || document.body.scrollTop > 0;
    }, 100);

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const getOrganizationSchema = () => (JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://agrosimex.pl/',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+48 668 0914',
          email: 'sklep.online@agrosimex.com.pl',
        },
      ],
    }));

    return {
      getOrganizationSchema,
      addBasePath,
      isSticky,
      showHeaderMenu,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isModalOpen,
    };
  },
});
