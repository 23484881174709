//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useImage } from '~/composables';
import imagePlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';

export default {
  name: 'ImageWrapper',
  props: {
    src: {
      type: String,
      required: true,
    },
    srcsets: {
      type: Array,
      default: () => [],
      validator: (value) => value.every((item) => item.resolution && item.src) || value.every((item) => item.src && item.width),
    },
    alt: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: null,
      validator: (value) => !isNaN(value),
    },
    height: {
      type: [Number, String],
      default: null,
      validator: (value) => !isNaN(value),
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
    imageTag: {
      type: String,
      default: 'img',
      validator: (value) => ['', 'img', 'nuxt-img'].includes(value),
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    fetchpriority: {
      type: String,
      default: 'auto',
      validator: (value) => ['high', 'low', 'auto'].includes(value),
    },
  },
  setup() {
    const { baseImageConfigQuery } = useImage();
    return {
      baseImageConfigQuery,
    };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    sortedSrcsets() {
      const arr = [...this.srcsets];

      arr.sort((setA, setB) => (setA.width && setB.width
        ? Number.parseInt(setA.width) - Number.parseInt(setB.width)
        : Number.parseInt(setA.resolution) - Number.parseInt(setB.resolution)));
      return arr;
    },
    srcset() {
      if (this.sortedSrcsets.length === 0) return null;
      return this.sortedSrcsets.reduce((str, set) => `${this.prefix(str)}${set.src} ${this.srcsetDescriptor(set)}`, '');
    },
    sizes() {
      const hasBreakpoints = this.sortedSrcsets.every((set) => set.breakpoint && set.width);
      if (!hasBreakpoints) return null;
      return this.sortedSrcsets.reduce(
        (str, set) => `${this.prefix(str)}${this.formatBreakpoint(set.breakpoint)}${this.formatDimension(set.width)}`,
        '',
      );
    },
    classes() {
      if (this.loaded) {
        return 'image image-loaded';
      }
      return 'image';
    },
    imageComponentTag() {
      return !this.$nuxt ? 'img' : this.imageTag || 'img';
    },
    isPlaceholderVisible() {
      return this.loaded || (!this.loaded && !this.placeholder);
    },
    attributes() {
      const {
        desktop = {}, tablet = {}, mobile = {}, ...nuxtImgConfig
      } = this.nuxtImgConfig;
      return this.imageTag === 'img' || this.imageTag === ''
        ? {
          ...this.$attrs,
          sizes: this.sizes,
          srcset: this.srcset,
        }
        : {
          ...this.$attrs,
          width: this.width ? this.width : null,
          height: this.height ? this.height : null,
          ...nuxtImgConfig,
        };
    },
    desktopSize() {
      return this.nuxtImgConfig.desktop?.width && this.nuxtImgConfig.desktop?.height
        ? `${this.nuxtImgConfig.desktop.width}x${this.nuxtImgConfig.desktop.height}`
        : null;
    },
    tabletSize() {
      return this.nuxtImgConfig.tablet?.width && this.nuxtImgConfig.tablet?.height
        ? `${this.nuxtImgConfig.tablet.width}x${this.nuxtImgConfig.tablet.height}`
        : null;
    },
    mobileSize() {
      return this.nuxtImgConfig.mobile?.width && this.nuxtImgConfig.mobile?.height
        ? `${this.nuxtImgConfig.mobile.width}x${this.nuxtImgConfig.mobile.height}`
        : null;
    },
    styles() {
      if (!this.width && !this.srcset && (this.imageTag === 'img' || this.imageTag === '')) {
        console.warn('Missing required prop width.');
      }
      if (!this.height && !this.srcset && (this.imageTag === 'img' || this.imageTag === '')) {
        console.warn('Missing required prop height.');
      }
      const sizeHandler = (size) => (size === null ? null : `${size}px`);
      return {
        '--_image-width': sizeHandler(this.width),
        '--_image-height': sizeHandler(this.height),
      };
    },
  },
  methods: {
    onLoad() {
      this.loaded = true;
    },
    formatResolution(resolution) {
      return (`${resolution}`).endsWith('x') ? resolution : `${resolution}x`;
    },
    formatDimension(size) {
      if (typeof size === null) return;
      if (
        ['%'].includes(`${size}`.slice(-1))
        || ['rem'].includes(`${size}`.slice(-3))
        || ['em', 'px', 'vw', 'vh'].includes(`${size}`.slice(-2))
        || !Number.parseInt(size, 10)
      ) {
        return size;
      }
      return `${size}px`;
    },
    formatBreakpoint(breakpoint) {
      return breakpoint ? `(max-width: ${breakpoint}px) ` : '';
    },
    prefix(str) {
      return str ? `${str}, ` : '';
    },
    srcsetDescriptor(srcset) {
      return srcset.width ? `${Number.parseInt(srcset.width) || ''}w` : this.formatResolution(srcset.resolution);
    },
  },
};
