





















































































import {
  defineComponent, ref, computed, useRouter,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import {
  SfImage, SfSearchBar, SfButton,
} from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
  },
  setup() {
    const router = useRouter();
    const term = ref('');

    const handleInput = (searchTerm: string) => {
      term.value = searchTerm;
    };
    const handleSearch = () => {
      if (!term.value) return;
      router.push({ path: '/sklep', query: { search: term.value } });
    };
    const removeSearch = () => {
      if (!term.value) return;
      term.value = '';
      const query = { ...router.currentRoute.query };
      if (query.search) {
        delete query.search;
        router.replace({ query });
      }
    };

    const inputNotEmpty = computed(() => term.value.trim() !== '');

    return {
      removeSearch,
      addBasePath,
      term,
      inputNotEmpty,
      handleInput,
      handleSearch,
    };
  },
});
