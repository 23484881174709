














import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import {
  SfImage,
} from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfImage,
  },
  setup() {
    return {
      addBasePath,
    };
  },
});
