


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import BrandTile from '~/components/Brands/BrandTile.vue';

export default defineComponent({
  name: 'MegaMenuBrands',
  components: { BrandTile, SkeletonLoader },
  props: {
    brands: {
      type: Array as PropType<any[] | null>,
      default: () => [],
    },
    brandsLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const onMouseLeave = () => {
      emit('on-mouse-leave');
    };

    return {
      onMouseLeave,
    };
  },
});
